import React from "react"
import { Link } from "gatsby"

const PostPreview = ({ post }) => (
  <div className="mt-lg mb-lg">
    <Link className="post-preview" to={post.frontmatter.path}>
      <p className="is-italic has-text-grey-light">{post.frontmatter.date}</p>
      <h2 className="is-size-3 has-text-grey-dark">{post.frontmatter.title}</h2>
      <p>{post.frontmatter.description}</p>
    </Link>
  </div>
)

export default PostPreview